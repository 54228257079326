import { render, staticRenderFns } from "./ButtonCompleteTag.vue?vue&type=template&id=cf2e53f0&"
import script from "./ButtonCompleteTag.vue?vue&type=script&lang=js&"
export * from "./ButtonCompleteTag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports