<template>
    <div>
        <PButton :loading="loading" class="btn" :class="[classColor, classSize]" @click="showModalConfirm = true">
            <translate>Set upload finished</translate>
        </PButton>

        <CModal :title="$gettext('Confirm upload completion')" :show.sync="showModalConfirm">
            <translate>All uploads must be declared complete before starting the AI-based tag process.</translate>
            <br><br>
            <translate>ATTENTION: IT WILL NOT BE POSSIBLE TO UPLOAD OTHER PHOTOS ONCE CONFIRMED</translate>
            <template #footer>
                <CButton @click="showModalConfirm = false" color="secondary">
                    <translate>Not yet</translate>
                </CButton>
                <CButton @click="setUploadAsCompleted" color="primary">
                    <translate>Yes, proceed</translate>
                </CButton>
            </template>
        </CModal>
    </div>
</template>


<script>
import PicaServiceService from "@/services/pica_service.service"
import PButton from "@/components/PButton"

export default {
    name: 'ButtonCompleteUpload',

    components: {
        PButton
    },

    props: {
        color: {
            type: String,
            default: 'info'
        },

        size: {
            type: String,
            default: 'sm'
        }
    },

    data() {
        return {
            loading: false,
            showModalConfirm: false,
        }
    },

    computed: {
        classColor() {
            return `btn-${this.color}`
        },

        classSize() {
            return `btn-${this.size}`
        }
    },

    methods: {
        setUploadAsCompleted() {
            this.loading = true
            console.log('setUploadAsCompleted')
            this.showModalConfirm = false
            PicaServiceService.updateUploadStatus(this.$store.getters['event/picaServiceId'])
                .then(() => {
                    this.notifyMessage({text: 'Upload completato', messageType: 'success'})
                    this.$emit('upload-completed')
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    console.log('savePublicationStatus() error', err.response.data)
                })
        },
    }
}
</script>
