<template>
    <div>
        <PButton :loading="loadingTag" class="btn" :class="[classColor, classSize]" @click="showModalConfirmTagCompleted = true">
            <translate>Set tag as completed</translate>

        </PButton>

        <CModal :title="$gettext('Confirm tag completion')" :show.sync="showModalConfirmTagCompleted">
            <translate>By setting the tag as completed it will no longer be possible to upload more photographs, are you sure?</translate>

            <template #footer>
                <CButton @click="showModalConfirmTagCompleted = false" color="secondary">
                    <translate>Cancel</translate>
                </CButton>
                <CButton @click="setTagAsCompleted" color="primary">
                    <translate>Set tag as completed</translate>

                </CButton>
            </template>
        </CModal>
    </div>
</template>


<script>
import PicaServiceService from "@/services/pica_service.service"
import PButton from "@/components/PButton"

export default {
    name: 'ButtonCompleteTag',

    components: {
        PButton
    },

    props: {
        color: {
            type: String,
            default: 'primary'
        },

        size: {
            type: String,
            default: 'sm'
        }
    },

    data() {
        return {
            loadingTag: false,
            showModalConfirmTagCompleted: false,
        }
    },

    computed: {
        classColor() {
            return `btn-${this.color}`
        },

        classSize() {
            return `btn-${this.size}`
        }
    },

    methods: {
        setTagAsCompleted() {
            this.loadingTag = true
            console.log('setTagAsCompleted')
            this.showModalConfirmTagCompleted = false
            PicaServiceService.updateTagStatus(this.$store.getters['event/picaServiceId'], true)
                .then(() => {
                    this.notifyMessage({text: 'Tag completato', messageType: 'success'})
                    this.$emit('tag-completed')
                    this.loadingTag = false
                    //this.refreshPicaServiceInStore()
                    //    .then(() => this.loadingTag = false)
                })
                .catch((err) => {
                    this.loadingTag = false
                    console.log('savePublicationStatus() error', err.response.data)
                })
        },
    }
}
</script>
