<template>
  <div>
    <h1 class="main-header" v-translate>Manual Tag</h1>
    <CRow>
      <CCol>
        <CCard class="no-borders">
          <CCardHeader class="px-2">

            <div class="card-header-actions"
                 v-if="canCloseTag || canCloseUpload || (canTag && isMagicTagEnabled)">
              <ButtonCompleteTag v-if="canCloseTag"
                                 color="primary"
                                 @tag-completed="refreshPicaServiceInStore"/>
              <ButtonCompleteUpload v-if="canCloseUpload"
                                    color="primary"
                                    @upload-completed="refreshPicaServiceInStore"/>
              <CButton v-if="canTag && isMagicTagEnabled"
                       @click="redirectToQuickTag()" color="primary">
                <translate>MagicTag</translate>
              </CButton>
            </div>
            <div class="card-header-actions"
                 v-else-if="isMagicTagEnabled && ! this.$store.state.event.picaService.is_tagging_completed">
              <translate>Processing</translate>
              <CSpinner class="ml-2" grow size="sm" color="primary"/>
            </div>

          </CCardHeader>
          <CCardBody class="px-0">
            <div class="table-responsive custom-table" v-if="mediaCountsByPhotographer !== null">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col" v-translate>Photographer</th>
                  <th scope="col" v-translate>Number of unrecognized photos</th>
                  <th scope="col" v-translate>Number of recognized photos</th>
                  <th scope="col" v-translate>Total number of photos</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(counts, index) in mediaCountsByPhotographer" :key="index">
                  <td>{{ counts.photographer_email }}</td>
                  <td>{{ counts.without_picacodes_count }}</td>
                  <td>{{ counts.with_picacodes_count }}</td>
                  <td>{{ counts.total_count }}</td>
                  <td class="text-right" v-if="canTag && !isMagicTagEnabled">
                    <CButton @click="redirectToQuickTag(counts.photographer_id)"
                             color="primary" size="sm" variant="outline">
                      Tag
                    </CButton>
                  </td>
                  <td class="text-right" v-else>
                    <CButton @click="redirectToQuickTag(counts.photographer_id, true)"
                             color="primary" size="sm" variant="outline">
                      <translate>See photos</translate>
                    </CButton>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th></th>
                  <th>{{ totalWithoutPicacodesCount }}</th>
                  <th>{{ totalWithPicacodesCount }}</th>
                  <th>{{ totalCount }}</th>
                </tr>
                </tfoot>
              </table>
            </div>

            <div v-else-if="loading">
               <ViewLoadingMessage :loading="true" />
            </div>

            <CAlert color="info" class="mb-0" v-else>
              <translate>There are no photos</translate>
            </CAlert>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import StatsService from "@/services/stats.service"
import ButtonCompleteTag from "@/components/ButtonCompleteTag"
import ButtonCompleteUpload from "@/components/ButtonCompleteUpload"
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {mapGetters} from "vuex";

export default {
  name: "EventTagging",

  components: {
      ViewLoadingMessage,
    ButtonCompleteTag,
    ButtonCompleteUpload
  },

  data() {
    return {
      loading: false,
      mediaCountsByPhotographer: null,
      totalCount: 0,
      totalWithoutPicacodesCount: 0,
      totalWithPicacodesCount: 0
    }
  },

  computed: {
    ...mapGetters('login', ['token']),
    ...mapGetters('event', ['isMagicTagEnabled','picaServiceId']),
    canCloseTag() {
      return !this.$store.state.event.picaService.is_tagging_completed && this.hasPerm('pica.set_tagging_completed')
    },
    canCloseUpload() {
      return this.$store.state.event.picaService.is_magictag_enabled && !this.$store.state.event.picaService.is_upload_completed
    },
    canTag() {
      // Pica taggers will always be able to access the tag even when declared as completed
      const isPicaStaffTagger = this.isInGroup('pica-managers') || this.isInGroup('photographers-helpers')
      if (isPicaStaffTagger) return true;
      const hasMagicTagPerm = this.hasPerm('dashboard.view_feature_magic_tag')
      return !this.$store.state.event.picaService.is_tagging_completed && (!this.isMagicTagEnabled || hasMagicTagPerm);
    }
  },

  mounted() {
    this.loading = true
    this.totalCount = 0
    this.totalWithoutPicacodesCount = 0
    this.totalWithPicacodesCount = 0

    StatsService.getMediaCountsByPhotographer(this.picaServiceId)
        .then((response) => {
          this.mediaCountsByPhotographer = response.data
          this.loading = false
          for (const i of this.mediaCountsByPhotographer) {
            this.totalCount += i.total_count
            this.totalWithoutPicacodesCount += i.without_picacodes_count
            this.totalWithPicacodesCount += i.with_picacodes_count
          }

        })
  },

  methods: {
    redirectToQuickTag(photographerId = null, viewerMode = false) {
      const appVars = {
        backUrl: window.location.href,
        token: this.token,
        currentLanguage: this.language
      }
      const appVarsBase64 = btoa(JSON.stringify(appVars))
      let url = `${process.env.VUE_APP_PICA_QUICKTAG_BASE_URL}/#?serviceId=${this.picaServiceId}&appVars=${appVarsBase64}`
      if (photographerId !== null) {
        url = `${url}&photographerId=${photographerId}`
      }
      if (viewerMode) {
        url = `${url}&mode=viewer`
      }
      window.location.replace(url)
    },

    refreshPicaServiceInStore() {
      return this.$store.dispatch('event/fetchPicaService')
    },
  }
}
</script>
